import showSnackbar from '@/helper/showSnackbar';
import { mapCheck, mapScoreCriteria } from '@/modules/CheckDetails/models';

export const NAMESPACE = 'checkDetails';

const initialState = () => ({
  check: null,
  scoreCriteria: [],
  loading: false,
  error: false,
  hasCheckedDocuments: false
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    setDetailedCheck(state, checkData) {
      state.check = checkData;
    },
    setScoreCriteria(state, criteriaList) {
      state.scoreCriteria = criteriaList;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setError(state, hasError) {
      state.error = hasError;
    },
    setHasCheckedDocuments(state, isChecked) {
      state.hasCheckedDocuments = isChecked;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchScoreCriteria({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get('/check-list-criteria-value-area');

        commit('setScoreCriteria', mapScoreCriteria(data));
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
        return { error };
      }
    },

    async fetchDetailedCheck({ state, commit, dispatch }, identifier) {
      commit('setLoading', true);
      commit('setError', false);
      commit('setDetailedCheck', null);
      commit('setHasCheckedDocuments', false);

      try {
        const [checkResponse] = await Promise.all([
          Vue.prototype.$http.get(`/check-show/${identifier}`, {}),
          state.scoreCriteria.length === 0 ? dispatch('fetchScoreCriteria') : undefined
        ]);

        commit('setDetailedCheck', mapCheck(checkResponse.data));
        commit('setLoading', false);
        return {};
      } catch (error) {
        commit('setError', true);
        commit('setLoading', false);
        return { error };
      }
    },

    async setPending({ dispatch }, { identifier, isPending }) {
      try {
        await Vue.prototype.$http.patch(
          `/check/${identifier}/${isPending ? 'pending' : 'not-pending'}`
        );

        dispatch('fetchDetailedCheck', identifier);

        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async sendDecision({ dispatch }, { identifier, decision, comment }) {
      try {
        await Vue.prototype.$http.post(`/check-add-user-decision/${identifier}`, {
          decision,
          comment
        });

        dispatch('fetchDetailedCheck', identifier);
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async fetchDocument(_, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/check-show-document/${identifier}`, {
          responseType: 'blob'
        });
        return { file: response.data };
      } catch (error) {
        return { error };
      }
    },

    async fetchAttachment(_, identifier) {
      try {
        const response = await Vue.prototype.$http.get(`/check-show-attachment/${identifier}`, {
          responseType: 'blob'
        });
        return { file: response.data };
      } catch (error) {
        return { error };
      }
    }
  }
});
