import VueRouter from 'vue-router';
import qs from 'qs';
import { RouteName, HOME_ROUTE_NAME } from '@/statics/routeName';

export default (Vue) => {
  Vue.use(VueRouter);

  const routes = [
    {
      path: '/login',
      component: () =>
        import(
          '@/modules/Authentication/components/LoginContainer.vue' /* webpackChunkName: "Login" */
        ),
      meta: {
        auth: false
      },
      children: [
        {
          path: '/',
          name: RouteName.LOGIN,
          component: () =>
            import('@/modules/Authentication/views/Login.vue' /* webpackChunkName: "Login" */),
          meta: {
            title: 'shared.pageTitles.login'
          }
        },
        {
          path: '/login/reset-password/:requestId',
          name: RouteName.RESET_PASSWORD,
          component: () =>
            import(
              '@/modules/Authentication/views/ResetPassword.vue' /* webpackChunkName: "ResetPassword" */
            ),
          meta: {
            title: 'shared.pageTitles.resetPassword',
            auth: false
          }
        }
      ]
    },
    {
      path: '/',
      component: () =>
        import(
          '@/modules/Authentication/views/Authorized.vue' /* webpackChunkName: "Authorized" */
        ),
      meta: {
        auth: true
      },
      redirect: { name: HOME_ROUTE_NAME },
      children: [
        {
          path: '/check-list',
          name: RouteName.CHECK_LIST,
          component: () =>
            import(/* webpackChunkName: "CheckList" */ '@/modules/CheckList/views/CheckList.vue'),
          meta: {
            title: 'shared.pageTitles.listView'
          }
        },
        {
          path: '/check-details/:id?',
          name: RouteName.CHECK_DETAILS,
          component: () =>
            import(
              /* webpackChunkName: "CheckDetails" */ '@/modules/CheckDetails/views/CheckDetails.vue'
            ),
          beforeEnter: (to, from, next) => {
            // details param is required to access view, but set as optional to avoid missing prop warning in sidebar
            if (!to.params.id) {
              return next({ name: HOME_ROUTE_NAME });
            }

            next();
          },
          meta: {
            title: 'shared.pageTitles.details'
          }
        }
      ]
    },
    {
      path: '*',
      redirect: { name: HOME_ROUTE_NAME }
    }
  ];

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result ? '?' + result : '';
    }
  });
};
