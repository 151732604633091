import { CheckStatus } from '@/statics/checkStatus';

export const mapChecks = (checks) =>
  checks.map((check) => ({
    id: check.id,
    score: check.score,
    status: check.isPending ? CheckStatus.PENDING : check.status,
    decision: check.finalDecision,
    creditorName: check.creditorName,
    creditorNumber: check.creditorNumber,
    debtorName: check.debtorName,
    debtorNumber: check.debtorNumber,
    voucherAmount: check.voucherAmount,
    creationDate: check.creationDate * 1000
  }));

export const mapScoreThresholds = ({ green, yellowToSingleCheck, yellowToDoubleCheck, red }) => [
  { start: green, end: yellowToSingleCheck },
  { start: yellowToSingleCheck + 1, end: yellowToDoubleCheck - 1 },
  { start: yellowToDoubleCheck, end: red }
];
