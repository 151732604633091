import { CheckStatus } from '@/statics/checkStatus';

export const mapScoreCriteria = (criteria) =>
  criteria.map((criterion) => ({
    key: criterion.criterionName,
    onBlacklist: criterion.blackList
  }));

export const mapCheck = (check) => ({
  id: check.id,
  score: check.score,
  status: check.isPending ? CheckStatus.PENDING : check.status,
  isOpen: check.status === CheckStatus.NEW,
  creationDate: check.creationDate * 1000,
  updateDate: check.updateDate * 1000,
  documentId: check.checkDocument?.id,
  attachmentIds: check.checkAttachments.map((attachment) => attachment.id),
  decision: check.finalDecision,
  userDecisions: check.userDecisions.map((item) => ({
    decision: item.decision,
    comment: item.message
  })),
  reasonCode: check.reasonCode,
  creditor: {
    name: check.additionalInformationCreditor.name,
    email: check.additionalInformationCreditor.email,
    number: check.additionalInformationCreditor.number,
    phone: check.additionalInformationCreditor.telephone
  },
  debtor: {
    name: check.additionalInformationDebtor.name,
    email: check.additionalInformationDebtor.email,
    number: check.additionalInformationDebtor.number,
    phone: check.additionalInformationDebtor.telephone
  },
  voucher: {
    number: check.additionalInformationVoucher.number,
    status: check.additionalInformationVoucher.status,
    amount: check.voucherAmount
  },
  scoreCriteria: {
    creditor: mapCriteria(check.underlyingCheckData.creditor),
    debtor: mapCriteria(check.underlyingCheckData.debtor),
    voucher: mapCriteria(check.underlyingCheckData.voucher)
  },
  pendingData: {
    isPending: check.isPending,
    assignee: check.pendingAssignee?.name
  }
});

const mapCriteria = (criteria) =>
  criteria.map((criterion) => ({
    formattedValue: criterion.outputValue,
    key: criterion.criteriaKey,
    score: criterion.score,
    value: criterion.value
  }));
