import showSnackbar from '@/helper/showSnackbar';

export const NAMESPACE = 'authentication';

const initialState = () => ({});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async requestPasswordReset(_, email) {
      try {
        await Vue.prototype.$http.post('/app-user/password-reset', {
          email
        });
        return {};
      } catch (error) {
        showSnackbar({});
        return { error };
      }
    },

    async resetPassword(_, { requestIdentifier, password }) {
      try {
        await Vue.prototype.$http.post(`/app-user/password-reset-confirm/${requestIdentifier}`, {
          password
        });
        return {};
      } catch (error) {
        return { error: { ...error, code: error.response.data.code } };
      }
    }
  }
});
