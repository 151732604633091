export const NAMESPACE = 'user';

const initialState = () => ({});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async updatePassword(
      _,
      { userIdentifier, currentPassword, newPassword, newPasswordConfirmation }
    ) {
      try {
        await Vue.prototype.$http.patch(`/app-user/${userIdentifier}/password-change`, {
          oldPassword: currentPassword,
          newPassword: newPassword,
          repeatNewPassword: newPasswordConfirmation
        });
        return {};
      } catch (error) {
        return { error: { ...error, code: error.response.data.code } };
      }
    }
  }
});
