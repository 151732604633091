import Vue from 'vue';
import App from '@/App.vue';
import i18n from '@/plugins/i18n';
import store from '@/plugins/store';
import vuetify from '@/plugins/vuetify';
import * as Sentry from '@sentry/vue';
import packageInfo from '../package.json';

import configureAuth from './plugins/vue-auth';
import configureAxios from '@/plugins/axios';
import configureBaseComponents from '@/shared/components/base';
import configureFilters from './helper/filter';
import configureRouter from '@/plugins/router';
import configureVeeValidate from '@/plugins/vee-validate';

/* mandatory order: router -> axios -> auth */
const router = configureRouter(Vue);
configureAxios(Vue);
configureAuth(Vue, router);
configureBaseComponents(Vue);
configureFilters(Vue);
configureVeeValidate(Vue);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_ENV,
  logErrors: true,
  release: packageInfo.version
});

// Global event bus
Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
