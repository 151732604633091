export default {
  user: {
    changePassword: 'Passwort ändern',
    logout: 'Logout',
    currentPassword: 'Aktuelles Passwort',
    newPassword: 'Neues Passwort',
    confirmNewPassword: 'Neues Passwort bestätigen',
    currentPasswordIncorrect: 'Aktuelles Passwort falsch',
    confirmationMismatch: 'Passwort und Bestätigung stimmen nicht überein'
  }
};
