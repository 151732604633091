<template>
  <validation-provider v-slot="{ errors }" v-bind="validationProps">
    <v-text-field
      :id="fieldId"
      v-model.trim="text"
      :append-icon="appendIcon"
      :error-messages="errors"
      :label="fieldLabelWithSupplement"
      :placeholder="fieldPlaceholder"
      :prepend-inner-icon="prependInnerIcon"
      data-testid="app-text-field__input"
      v-bind="$attrs"
    >
      <template v-if="appendFunction" #append>
        <v-icon @click="executeCallback(appendFunction)">{{ appendIcon }}</v-icon>
      </template>

      <template v-if="prependInnerFunction" #prepend-inner>
        <v-icon @click="executeCallback(prependInnerFunction)">{{ prependInnerIcon }}</v-icon>
      </template>

      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName"></slot>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppTextField',

  mixins: [FormValidationMixin],

  props: {
    appendFunction: {
      type: Function,
      default: null
    },
    appendIcon: {
      type: String,
      default: ''
    },
    prependInnerFunction: {
      type: Function,
      default: null
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    passive: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    }
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    validationProps() {
      return {
        name: this.validationName,
        rules: this.validationRules,
        mode: this.passive ? 'passive' : this.mode,
        ...(this.vid ? { vid: this.vid } : null)
      };
    }
  },
  methods: {
    executeCallback(callback) {
      if (callback === null) {
        return;
      }

      callback();
    }
  }
};
</script>

<style scoped lang="scss">
.app-text-field__input ::v-deep input {
  margin-top: 8px !important;
}

.v-input ::v-deep .v-label {
  @include text-uppercase;
  letter-spacing: 1.5px;
  color: var(--v-text-base);
  margin-bottom: 8px;
}
</style>
