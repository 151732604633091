import Vue from 'vue';
import VueI18n from 'vue-i18n';

class CustomVueI18n extends VueI18n {
  constructor(props) {
    super(props);
  }

  // extend $te to check also for fallback locale
  _te(key, locale, messages, ...args) {
    const chain = this._getLocaleChain(locale, this.fallbackLocale);
    return chain.some((lang) => super._te(key, lang, messages, ...args));
  }
}
Vue.use(CustomVueI18n);

// look for all translations folders in project src
function loadLocaleMessages() {
  const locales = require.context('@/', true, /translations\/[A-Za-z0-9-_,\s]+\.js$/i);
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = {
        ...messages[locale],
        ...locales(key).default
      };
    }
  });

  return messages;
}

export default new CustomVueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
});
