import { CheckStatus } from '@/statics/checkStatus';
import { Decision } from '@/statics/decision';

export default {
  shared: {
    pageTitles: {
      details: 'Belegdetails',
      listView: 'Listenansicht',
      login: 'Login',
      resetPassword: 'Passwort zurücksetzen'
    },
    error: 'Fehler',
    errorInfo: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
    errorAlertTitle: 'Fehler beim Laden der Daten',
    reloadPage: 'Seite neu laden',
    noResults: 'Keine Einträge',
    resultsEmpty: 'Es sind noch keine Einträge vorhanden.',
    noMatchingResults: 'Es wurden keine Einträge gefunden, die den Suchkriterien entsprechen.',
    actions: {
      cancel: 'Abbrechen',
      send: 'Absenden',
      toLogin: 'Zum Login',
      ok: 'OK'
    },
    decision: 'Entscheidung',
    status: 'Status',
    score: 'Score',
    created: 'Erstellt',
    creditor: 'KMU',
    debtor: 'Debitor',
    voucher: 'Beleg',
    attachment: 'Anhang',
    amount: 'Betrag',
    decisions: {
      [Decision.APPROVED]: 'akzeptiert',
      [Decision.DECLINED]: 'abgelehnt',
      [Decision.OPEN]: 'offen',
      [Decision.CLOSED]: 'geschlossen'
    },
    checkStatus: {
      [CheckStatus.DONE]: 'erledigt',
      [CheckStatus.PENDING]: 'in Prüfung',
      [CheckStatus.NEW]: 'neu'
    },
    from: 'von',
    to: 'bis',
    atDate: 'am {date}',
    toDate: 'bis {date}',
    fromDate: 'ab {date}',
    betweenDate: '{a} - {b}',
    lessOrEqualThan: '≤ {value}',
    greaterOrEqualThan: '≥ {value}',
    between: 'zwischen {a} und {b}',
    dateHint: 'TT.MM.JJJJ',
    dateNotValid: 'Datumsformat nicht zulässig',
    minDateNotValid: 'Nur Angaben ab dem {date} zulässig',
    maxDateNotValid: 'Nur Angaben bis zum {date} zulässig',
    passwordRequirements:
      'Ihr Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie Sonderzeichen enthalten.',
    confirmationNotEqual: 'Die Eingaben stimmen nicht überein',
    changePasswordInfo: 'Vergeben Sie ein neues Passwort, um Ihren Account zu schützen.',
    changedPassword: 'Passwort geändert',
    changePasswordNow: 'Jetzt Passwort ändern'
  }
};
