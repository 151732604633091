<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import ProviderInfo from '@/modules/Authentication/statics/providerInfo';

export default {
  name: 'App',

  watch: {
    '$route.name': {
      handler() {
        this.setPageTitle();
      },
      immediate: true
    }
  },

  methods: {
    setPageTitle() {
      const productTitle = ProviderInfo.NAME;
      const pageTitle = this.$te(this.$route.meta.title) ? this.$t(this.$route.meta.title) : '';

      document.title = pageTitle ? `${pageTitle} - ${productTitle}` : productTitle;
    }
  }
};
</script>

<style lang="scss">
@import '~@/styles/fonts.scss';
@import '~@/styles/global.scss';
@import '~@/styles/typography.scss';
</style>
