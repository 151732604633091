import Vue from 'vue';

/**
 * Print error and show AppSnackbarInfo with message
 * @param {String} [text='shared.error'] - translation key
 * @param {String} [subtext='shared.errorInfo'] - translation key
 * @param {'error' | 'success'} [type='error'] - border style
 * @param {Object} [icon=undefined] - icon
 * @param {Number} [timeout='5000'] - ms until snackbar is hidden
 */
export default ({
  text = 'shared.error',
  subtext = 'shared.errorInfo',
  type = 'error',
  icon = undefined,
  timeout = 5000
}) => {
  Vue.prototype.$eventHub.$emit('snackbar', { text, subtext, type, icon, timeout });
};
