import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';

export const AUTH_TOKEN_KEY = 'vrs_auth_token_default';
export const REFRESH_TOKEN_KEY = 'vrs_refresh_token_default';

const REFRESH_API = '/app-user/token/refresh';

export default (Vue, router) => {
  const bearerAuth = {
    request: function (req, token) {
      if (req.url === REFRESH_API) {
        req.data = {
          refresh_token: this.token(this.options.refreshTokenKey)
        };
        return;
      }
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: 'Bearer ' + token
      });
    },
    response: function (res) {
      const data = res.data || {};

      if (data.refresh_token) {
        this.token(this.options.refreshTokenKey, data.refresh_token);
      }

      return data.token;
    }
  };

  Vue.use(VueAuth, {
    plugins: {
      http: Vue.axios,
      router: router
    },
    drivers: {
      auth: bearerAuth,
      http: driverHttpAxios,
      router: driverRouterVueRouter
    },
    options: {
      tokenDefaultKey: AUTH_TOKEN_KEY,
      refreshTokenKey: REFRESH_TOKEN_KEY,
      stores: ['storage'],
      rolesKey: 'roles',
      loginData: {
        url: 'app-user-login',
        method: 'POST',
        redirect: '/',
        fetchUser: true,
        staySignedIn: true
      },
      fetchData: {
        url: 'current-user',
        method: 'GET',
        enabled: true
      },
      cookie: {
        HttpOnly: true
      },
      refreshData: {
        url: REFRESH_API,
        method: 'POST',
        enabled: true,
        interval: 45
      },
      parseUserData: (data) => data
    }
  });
};
