import { format, isValid, isMatch, parseISO } from 'date-fns';

/**
 * @param {String|Date} date
 * @param {String} [fallback='-']
 * @return {string} formatted | -
 */
export default function formatDate(date, fallback = '-') {
  if (!date || isNaN(date)) {
    return fallback;
  }

  // convert received timestamp in seconds to required date format in milliseconds
  // more options: https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString#Syntax
  date = new Date(Number(date)).toLocaleDateString('de-DE', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
  return date;
}

/**
 * used to format input from date picker before submit to API
 * @param {String} date in ISO format - e.g. 2020-05-13
 * @return {Number} formatted in seconds - e.g. 1589377445
 */
export const formatDateToTimestamp = (date) => {
  return String(Date.parse(date) / 1000);
};

/**
 * @param {Number} seconds - e.g. 1589377445
 * @param {String} [formatString='yyyy-MM-dd'] - format of return value
 * @return {String} formatted - e.g. 2020-05-13
 */
export const formatTimestampToDate = (seconds, formatString = 'yyyy-MM-dd') => {
  const time = parseInt(seconds) * 1000;
  return format(new Date(time), formatString);
};

/**
 * @param {String} date - e.g. 13.05.2020
 * @return {String} formatted - e.g. 2020-05-13
 */
export const formatLocalToIso = (date) => {
  if (!isMatch(date, 'dd.MM.yyyy')) {
    return '';
  }

  const [day, month, year] = date.split('.');
  if (!day || !month || !year) {
    return '';
  }

  return `${year.padStart(4, '20')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

/**
 * @param {String} date - e.g. 2020-05-13
 * @return {String} formatted - e.g. 13.05.2020
 */
export const formatIsoToLocal = (date) => {
  if (!isValid(parseISO(date))) {
    return date;
  }

  return format(parseISO(date), 'dd.MM.yyyy');
};
