<template>
  <v-btn
    :id="id || title || undefined"
    :class="{ 'app-btn': true, 'app-btn--uppercase': uppercase }"
    :color="color"
    :disabled="disabled || loading"
    :icon="icon"
    :loading="loading"
    :plain="plain"
    :text="text"
    depressed
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>{{ $t(title) }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppBtn',

  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    uppercase: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.app-btn:not(.v-btn--round) {
  text-transform: none;
  font-size: 16px;
  letter-spacing: 0.045em;
  min-height: 48px;
  height: auto;
  border-radius: 2px;
  flex-direction: column-reverse;
  max-width: 100%;
  min-width: unset;

  &.app-btn--uppercase {
    @include text-uppercase;
  }

  @include media-query(mobile-only) {
    white-space: normal;
    word-break: break-word;
    hyphens: auto;
    padding: 0 8px;
  }

  &.v-btn--text,
  &.v-btn--plain {
    padding: 0;
    min-height: unset;
    height: fit-content;
  }

  &.v-btn--plain:not(.v-btn--active):not(.v-btn--loading) {
    ::v-deep .v-btn__content {
      opacity: 1;
    }

    &:focus,
    &:hover {
      color: var(--v-text-base) !important;
    }
  }

  &.v-btn--disabled {
    &.v-btn--outlined {
      color: var(--v-grey-65-base) !important;
    }

    &.v-btn--has-bg {
      color: var(--v-white-base) !important;
      background-color: var(--v-grey-65-base) !important;
    }
  }
}
</style>
