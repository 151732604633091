export const FilterQuery = {
  CREATION_DATE: 'creationDate',
  CREDITOR_NAME: 'creditorName',
  CREDITOR_NUMBER: 'creditorNumber',
  DEBTOR_NAME: 'debtorName',
  DEBTOR_NUMBER: 'debtorNumber',
  DECISION: 'finalDecision',
  SCORE: 'score',
  STATUS: 'status'
};
