import Vue from 'vue';
import Vuex from 'vuex';

import authenticationStore, { NAMESPACE as AUTH_NAMESPACE } from '@/modules/Authentication/store';
import checkDetailsStore, {
  NAMESPACE as CHECK_DETAILS_NAMESPACE
} from '@/modules/CheckDetails/store';
import checkListStore, { NAMESPACE as CHECK_LIST_NAMESPACE } from '@/modules/CheckList/store';
import userStore, { NAMESPACE as USER_NAMESPACE } from '@/modules/User/store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    reset({ commit }) {
      commit(`${AUTH_NAMESPACE}/resetState`);
      commit(`${CHECK_DETAILS_NAMESPACE}/resetState`);
      commit(`${CHECK_LIST_NAMESPACE}/resetState`);
      commit(`${USER_NAMESPACE}/resetState`);
    }
  },
  modules: {
    [AUTH_NAMESPACE]: authenticationStore(Vue),
    [CHECK_DETAILS_NAMESPACE]: checkDetailsStore(Vue),
    [CHECK_LIST_NAMESPACE]: checkListStore(Vue),
    [USER_NAMESPACE]: userStore(Vue)
  }
});
