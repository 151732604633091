import qs from 'qs';
import { FilterQuery } from '@/modules/CheckList/statics/filterQuery';
import { mapChecks, mapScoreThresholds } from '@/modules/CheckList/models';

export const NAMESPACE = 'checkList';

const initialState = () => ({
  checks: [],
  checksCount: 0,
  checksEmpty: true,
  loading: false,
  error: false,
  filterItems: {
    [FilterQuery.CREDITOR_NAME]: [],
    [FilterQuery.CREDITOR_NUMBER]: [],
    [FilterQuery.DEBTOR_NAME]: [],
    [FilterQuery.DEBTOR_NUMBER]: []
  },
  scoreThresholds: []
});

export default (Vue) => ({
  namespaced: true,

  state: initialState(),

  mutations: {
    setChecks(state, { checks, count, empty }) {
      state.checks = checks;
      state.checksCount = count;
      state.checksEmpty = empty;
    },
    setFilterItems(state, filterItems) {
      state.filterItems = filterItems;
    },
    setScoreThresholds(state, thresholds) {
      state.scoreThresholds = thresholds;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setError(state, hasError) {
      state.error = hasError;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }
  },

  actions: {
    async fetchChecks({ commit }, { page, rowsPerPage, filters, sorting }) {
      commit('setLoading', true);
      commit('setError', false);

      try {
        const { data } = await Vue.prototype.$http.get(`/check-list`, {
          params: { page, rowsPerPage, filters, sorting },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' });
          }
        });
        const checks = data.checks;
        const count = data.count;
        const empty = data.empty;

        commit('setChecks', { checks: mapChecks(checks), count, empty });
        commit('setLoading', false);
        return {};
      } catch (error) {
        commit('setError', true);
        commit('setChecks', {
          checks: [],
          count: 0,
          empty: true
        });
        commit('setLoading', false);
        return { error };
      }
    },

    async fetchFilterItems({ commit }) {
      try {
        const filterProperties = [
          FilterQuery.CREDITOR_NAME,
          FilterQuery.CREDITOR_NUMBER,
          FilterQuery.DEBTOR_NAME,
          FilterQuery.DEBTOR_NUMBER
        ];

        const responses = await Promise.all(
          filterProperties.map((property) => Vue.prototype.$http.get(`/filter-items/${property}`))
        );

        commit(
          'setFilterItems',
          filterProperties.reduce((acc, property, index) => {
            acc[property] = responses[index].data;
            return acc;
          }, {})
        );
        return {};
      } catch (error) {
        return { error };
      }
    },

    async fetchScoreThresholds({ commit }) {
      try {
        const { data } = await Vue.prototype.$http.get(`/filter-score-thresholds`);

        commit('setScoreThresholds', mapScoreThresholds(data));
        return {};
      } catch (error) {
        return { error };
      }
    }
  }
});
