import axios from 'axios';
import VueAxios from 'vue-axios';

export default (Vue) => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_VRS_API_URL
  });

  Vue.use(VueAxios, instance);
};
