export default {
  checkList: {
    adjustFilters: 'Filter anpassen',
    clearFilters: 'Filter löschen',
    creditorName: 'KMU-Name',
    creditorNo: 'KMU-Nr.',
    debtorName: 'Debitor-Name',
    debtorNo: 'Debitor-Nr.'
  }
};
