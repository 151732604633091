export default {
  authentication: {
    emailAddress: 'E-Mail-Adresse',
    password: 'Passwort',
    passwordConstraints: 'muss mindestens eine Zahl und ein Sonderzeichen enthalten',

    login: {
      title: 'Login',
      login: 'Anmelden',
      logout: 'Ausloggen',
      welcomeBack: 'Willkommen zurück in Ihrem {productName}-Portal!',
      badCredentials:
        'Benutzername oder Passwort falsch. Wenn Sie Probleme beim Login haben, wenden Sie sich bitte an unseren Support.',
      phone: 'Telefon',
      email: 'E-Mail',
      forgotPassword: 'Passwort vergessen'
    },

    resetPassword: {
      title: 'Passwort zurücksetzen',
      resetPasswordHelp:
        'Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passwortes zu erhalten.',
      passwordHint:
        'Zur Erinnerung: Ihr Passwort ist mindestens 8 Zeichen lang, enthält ein Sonderzeichen, eine Zahl und einen Großbuchstaben',
      sentMail:
        'Wir haben Ihre Anfrage erhalten und senden Ihnen eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passwortes.',
      repeatPassword: 'Passwort wiederholen',
      confirmUpdate: 'Vielen Dank! Ihr Passwort wurde geändert.',
      confirmReset:
        'Ihr Passwort wurde zurückgesetzt. Sie können jetzt zum Login zurückkehren, um sich mit Ihren neuen Daten anzumelden.',
      oldPasswordUsed:
        'Das eingegebene Passwort stimmt mit dem alten überein. Geben Sie ein neues Passwort ein.',
      requestExpired:
        'Die Anfrage ist nicht mehr gültig. Bitte fordern Sie das Zurücksetzen des Passwortes neu an.',
      startNewRequest: 'Neuen Link anfordern'
    }
  }
};
