export const RouteName = {
  CHECK_DETAILS: 'checkDetails',
  CHECK_LIST: 'checkList',
  LOGIN: 'login',
  RESET_PASSWORD: 'resetPassword'
};

export const HOME_ROUTE_NAME = RouteName.CHECK_LIST;

export default RouteName;
