<template>
  <div
    ref="lottieAnimation"
    data-testid="animated-icon"
    class="animated-icon__container"
    :style="cssVars"
    v-bind="$attrs"
    v-on="$listeners"
  ></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  props: {
    icon: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    speed: {
      type: Number,
      default: 1
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    animation: null
  }),

  computed: {
    cssVars() {
      return {
        '--icon-width': this.width !== undefined ? `${this.width}` : 'auto',
        '--icon-min-width': this.height !== undefined ? 'fit-content' : `${this.width}`,
        '--icon-height': this.height !== undefined ? `${this.height}` : 'auto'
      };
    }
  },

  watch: {
    icon() {
      this.init();
    },

    value(value) {
      if (value && this.animation) {
        this.playAnimation();
      }
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      if (this.animation) {
        this.animation.destroy();
      }

      this.animation = lottie.loadAnimation({
        container: this.$refs.lottieAnimation,
        animationData: this.icon,
        autoplay: this.autoplay,
        loop: this.loop,
        renderer: 'svg'
      });

      this.animation.addEventListener('complete', () => this.stopAnimation());
      this.animation.setSpeed(this.speed);
    },
    playAnimation() {
      this.animation.play();
    },
    stopAnimation() {
      this.animation.stop();
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped lang="scss">
.animated-icon__container {
  width: var(--icon-width);
  min-width: var(--icon-min-width);
  height: var(--icon-height);
  display: inline-block;

  ::v-deep svg {
    /* The default transform: translate3d(0px, 0px, 0px) has a blurring effect on the svg.
      It is probably set to force hardware acceleration and improve performance,
      so this should be reconsidered if the performance gets perceptibly worse. */
    transform: revert !important;
  }
}
</style>
