export const ProviderInfo = {
  LOGO_FINTECRITY: require('@/assets/images/FinTecrity-logo.svg'),
  LOGO_FINTECRITY_GREY: require('@/assets/images/FinTecrity-logo-grey.svg'),
  LOGO_VRS: require('@/assets/images/vrs-logo.svg'),
  NAME: 'FinVprotect',
  SERVICE_HOTLINE: '+49 (0)621 399957-60',
  SERVICE_MAIL: 'service@fintecrity.de'
};

export default ProviderInfo;
