import { ReasonCode } from '@/statics/reasonCode';

export default {
  checkDetails: {
    general: 'Allgemein',
    mail: 'Mail',
    date: 'Datum',
    voucherNumber: 'Beleg-Nummer',
    unknown: 'nicht bekannt',
    yes: 'ja',
    no: 'nein',
    setPending: 'In Prüfung setzen',
    assignee: 'Prüfer',
    cancelPending: 'Prüfung abbrechen',
    checkDocument: 'Beleg bitte ansehen!',
    documentNotAvailable:
      'Der Beleg steht nicht mehr zur Verfügung. Er kann im SAP oder FDL-Portal eingesehen werden.',
    comment: 'Kommentar',
    pleaseComment: 'Begründen Sie bitte hier Ihre Entscheidung',
    approve: 'Akzeptieren',
    titleApprove: 'Wollen Sie wirklich akzeptieren?',
    yesApprove: 'Ja, akzeptieren',
    decline: 'Ablehnen',
    titleDecline: 'Wollen Sie wirklich ablehnen?',
    yesDecline: 'Ja, ablehnen',
    documentCheckRequired: 'Bitte überprüfen Sie den Beleg, bevor Sie Ihre Entscheidung treffen.',
    decisionSubmitted: 'Ihre Entscheidung wurde erfasst',
    showDecisions: 'Gründe ansehen',
    automatically: 'automatisch {decision}',
    explanationFourEyesPrinciple:
      'Bei bereits einer abgelehnten Stimme (nach Vier-Augen-Prinzip) wird der Check abgelehnt!',

    criteria: {
      creditor: {
        backCalculationRate: 'Rückrechnungsquote',
        complaintRate: 'Reklamationsquote',
        conspicuousnessIndex: 'Auffälligkeitsindex',
        debtorPaymentLastDaysRate: 'Kurzfristiges Zahlverhalten (90 Tage)',
        isDebtorMail: 'KMU-E-Mail ist Debitoren-E-Mail',
        limitRate: 'Ankaufrahmenausschöpfung bei Ankauf',
        payoutTotalAmount: 'Auszahlungen ohne Debitorenzahlungen',
        undercoverAmount: 'KMU-Unterdeckung',
        verityRiskRate: 'Rückzahlungsquote',
        manualCheckMandatory: 'KMU soll immer geprüft werden'
      },
      debtor: {
        creditAdviceRate: 'Gutschriftsquote',
        cspIdClass: 'Stabilität der Debitorenidentität',
        directDebitRate: 'Direktzahlungsquote',
        isCreditor: 'KMU ist Debitor',
        isCreditorAddress: 'KMU-Adresse ist Debitorenadresse',
        isCreditorMail: 'KMU-E-Mail ist Debitoren-E-Mail',
        houseSuspicious: 'Auffällige Adresse',
        limitRate: 'Limitausschöpfung bei Ankauf',
        trustRank: 'Vertrauenswürdigkeit der Person'
      },
      voucher: {
        amount: 'Betrag',
        count: 'Laufende Belegnummer',
        paymentDeadlineDays: 'Zahlungsziel'
      }
    },
    reasons: {
      [ReasonCode.ID_000]: 'Der Veritätsscore liegt unter 5.000.',
      [ReasonCode.ID_101]: 'Der KMU ist der Debitor (Rechnung an sich selbst).',
      [ReasonCode.ID_102]: 'Die Adresse des KMUs ist identisch mit der Adresse des Debitors.',
      [ReasonCode.ID_103]:
        'Die E-Mail-Adresse des KMUs ist identisch mit der E-Mail-Adresse des Debitors.'
    }
  }
};
