import de from 'vee-validate/dist/locale/de';
import i18n from '@/plugins/i18n';
import { confirmed, email, min, required } from 'vee-validate/dist/rules';
import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate';
import { formatIsoToLocal, formatLocalToIso } from '@/helper/filter/formatDate';
import { isValid, parseISO } from 'date-fns';

export default (Vue) => {
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);

  localize('de', de);

  // register available rules from VeeValidate
  const rules = { confirmed, email, min, required };
  Object.keys(rules).forEach((key) => extend(key, rules[key]));

  extend('confirmed', {
    message: i18n.t('shared.confirmationNotEqual')
  });

  extend('is_date', {
    message: i18n.t('shared.dateNotValid'),
    validate: (value) => {
      return isValid(parseISO(formatLocalToIso(value)));
    }
  });

  extend('max_date', {
    validate: (value, { maxValue }) => {
      return formatLocalToIso(value) <= maxValue;
    },
    params: ['maxValue'],
    message: (field, { maxValue }) =>
      i18n.t('shared.maxDateNotValid', { date: formatIsoToLocal(maxValue) })
  });

  extend('min_date', {
    message: (field, { minValue }) =>
      i18n.t('shared.minDateNotValid', { date: formatIsoToLocal(minValue) }),
    validate: (value, { minValue }) => {
      return formatLocalToIso(value) >= minValue;
    },
    params: ['minValue']
  });

  extend('password_constraints', {
    message: (field) => field + ' ' + i18n.t('authentication.passwordConstraints'),
    validate: (value) => /(?=.*\d)(?=.*\W)/.test(value)
  });
};
