import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de';
import '@mdi/font/css/materialdesignicons.css';
import colors from '@/styles/colors.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ...colors
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
